import LocalizedStrings from 'react-localization';

let languages = new LocalizedStrings({
  en:{
    isRTL: false,
    home: "Home",
    causes: "Causes",
    contact_now: "Contact Now",
    about: "About Us",
    policies: "Policies",
    events: "Events",
    what_we_do: "What We Do",
    where_we_work: "Where We Work",
    resources: "resources",
    latest: "Latest",
    donate: "Donate"
  },
});



export default languages;