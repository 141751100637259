import React, {useEffect, useState} from "react";
import styles from './style.module.css';
import { NavLink } from "react-router-dom";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import SweetAlert from "../../Components/SweetAlert";
const Footer = (props) =>
{
  const [globalState] = useStore();


  const defaultState = {
    name: '',
    email: '',
    message: ''
  }

  const [contactForm, setContactForm] = useState({...defaultState});


  const onChange = (type, value) =>
  {
    setContactForm(prev => ({...prev, [type]: value}))
  }

  const onSubmit = async (e) => {
    e.preventDefault();

      const  emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; ;
  
      for (const key in contactForm) {
        if(key === "email" && (!emailPattern.test(contactForm[key])))
          return SweetAlert("error", "Please Enter Correct Email Address!");
        if(contactForm[key].length < 3)
          return SweetAlert("error", "All the contacts fields are requierd and at least must be 3 character long!");
      }
  
      try {
        
        
        const response = await fetch(serverPath("/contact"), {
          method: "POST",
          headers: {
            "Content-Type": "Application/json"
          },
          body: JSON.stringify(contactForm)
        });
  
        const objData = await response.json();
  
        if (objData.status === "failure") {
          return SweetAlert("error", objData.error.message);
        }
        SweetAlert("success", "Thank You! \n An Expert From Contact Us Will Contact You Shortly.");
  
        setContactForm({...defaultState});
  
      } catch (error) {
        return SweetAlert("error", error.message);
      }
      
  }

  const sortStatics = globalState.statics.sort((a, b) => ((a.order.charCodeAt(0)) - (b.order.charCodeAt(0))));

  return (
    <footer className="footer-area section-padding-100">
      <div className="main-footer-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="footer-widget-area">
                <div className="footer-logo h-logo">
                  <img src="img/core-img/logo.png" alt="" />
                </div>
                <p>
                  {sortStatics.length >= 1 && (
                    sortStatics[0].description
                  )}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="footer-widget-area">
                <div className="widget-title">
                  <h6>Products</h6>
                </div>
                <div className="widget-blog-post">
                  { globalState.products.slice(0, 3).map((product) => (
                  <div className="widget-single-blog-post d-flex" key={product.id}>
                    <div className="widget-post-thumbnail">
                      <img src={serverPath(product.imagePath)} alt="" />
                    </div>
                    <div className="widget-post-content">
                      <NavLink to="/products">{product.name}</NavLink>
                      <p>{product.formula}</p>
                    </div>
                  </div>
                  ))

                  }
                  
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="footer-widget-area">
                <div className="widget-title">
                  <h6>Contact Form</h6>
                </div>
                <div className="footer-contact-form">
                  <form action="#" method="post" onSubmit={onSubmit}>
                    <input type="text" className="form-control border-top-0 border-right-0 border-left-0" name="footer-name" id="footer-name" placeholder="Name"  onChange={(e) => onChange("name", e.target.value)} value={contactForm.name}/>
                    <input type="email" className="form-control border-top-0 border-right-0 border-left-0" name="footer-email" id="footer-email" placeholder="Email"  onChange={(e) => onChange("email", e.target.value)} value={contactForm.email}/>
                    <textarea name="message" className="form-control border-top-0 border-right-0 border-left-0" id="footerMessage" placeholder="Message" onChange={(e) => onChange("message", e.target.value)} value={contactForm.message}></textarea>
                    <button type="submit" className="btn medilife-btn">Contact Us <span>+</span></button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="footer-widget-area">
                <div className="widget-title">
                  <h6>Map</h6>
                </div>
                <div className="footer-newsletter-area ">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3397.728632349345!2d65.7059395!3d31.6138948!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed671179f996b53%3A0x95dcde8bdaf14a8!2sAyubi+Roghtia+medicin+market!5e0!3m2!1sen!2s!4v1550399108145" style={{border: 0,  width: "100%", height: "250px"}} ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="bottom-footer-content">
                <div className="copywrite-text">
                  <p><a target="_blank" href="https://www.jahanmal.com">COPYRIGHT © 2023 BY Jahanmal Team</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}



export default Footer;