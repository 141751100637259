import React, {useEffect} from 'react';
import AuthProvider from './authContext';
import AppWrapper from './Containers/AppWrapper'
import Footer from './Containers/Footer';
import Navbar from './Containers/Navbar';
import {useNavigate} from 'react-router-dom'
function App() {
  const navigate = useNavigate();
  useEffect(() => {
    if(window.location.pathname.split("/").length > 2)
    {
      document.body.style.display = "none";
      window.location.replace('/')
    }else
    {
      navigate('/', {replace: true})
    }
  }, [])
  return (
    <>
      <AuthProvider>
        <Navbar />
        <AppWrapper />
        <Footer />
      </AuthProvider>
    </>
  );
}

export default App;
