import React, {useEffect, useState} from "react";
import styles from './style.module.css';
import { NavLink } from "react-router-dom";
import useStore from "../../store/store";
import serverPath from '../../utils/serverPath'
const Home = (props) =>
{

    const [globalState] = useStore();

    useEffect(() =>
    {
        window.looping("HOME");
    }, [])

    const sortStatics = globalState.statics.sort((a, b) => ((a.order.charCodeAt(0)) - (b.order.charCodeAt(0))));
  return (
    <>
      <section className="hero-area">
        <div className="hero-slides owl-carousel">
        {   globalState.heros.map((hero) => 
        (
            <div className="single-hero-slide bg-img bg-overlay-white" style={{height: 800, backgroundImage: `url(${serverPath(hero.imagePath)})`}} key={hero.id}>
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-12">
                            <div className="hero-slides-content">
                                <h2 data-animation="fadeInUp" data-delay="100ms" style={{maxWidth: 700}}>{hero.title}</h2>
                                <h6 data-animation="fadeInUp" data-delay="400ms">{hero.description}</h6>
                                <NavLink to="/aboutus" className="btn medilife-btn mt-50" data-animation="fadeInUp" data-delay="700ms">Discover More <span>+</span></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
        }
        </div>
      </section>
      
    <div className="medilife-features-area mt-100">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-lg-12">
                    <div className="features-content">
                        <h3 className="mb-30">Welcome To Hikmat Hanifi LTD</h3>
                        <p>
                        {sortStatics.length >= 1 && (
                            sortStatics[0].description
                        )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container align-items-center mb-50 mt-100">
        <div className="features-content">
            <h3>
                Our Products
            </h3>
        </div>
    </div>
    <div className="medilife-gallery-area owl-carousel">
        {globalState.products.map((product) => (
            <div className="single-gallery-item" key={product.id}>
                <img src={serverPath(product.imagePath)} alt="" />
                <div className="view-more-btn">
                    <a href={serverPath(product.imagePath)} className="btn gallery-img">See More +</a>
                </div>
            </div>
        ))
        }
    </div>

    <div className="container">
        <div className="row section-padding-100-50 ">
            <div className="col-12">
                <div className="features-content">
                    <h3 className="mb-50">About Company</h3>
                </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3">
                <div className="single-cool-fact-area text-center mb-100">
                    <i className="icon-hospital-6"></i>
                    <h2><span className="counter">{globalState.branches.length || 0}</span></h2>
                    <h6>Branches</h6>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
                <div className="single-cool-fact-area text-center mb-100">
                    <i className="icon-medicine-2"></i>
                    <h2><span className="counter">{globalState?.cinfos[0]?.amount}</span></h2>
                    <h6>Products</h6>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
                <div className="single-cool-fact-area text-center mb-100">
                    <i className="icon-doctor-1"></i>
                    <h2><span className="counter">{globalState?.cinfos[1]?.amount}</span></h2>
                    <h6>Customers</h6>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
                <div className="single-cool-fact-area text-center mb-100">
                    <i className="icon-hospital"></i>
                    <h2><span className="counter">{globalState.partners.length || 0}</span></h2>
                    <h6>Partners</h6>
                </div>
            </div>
        </div>
    </div>

    <div className="medilife-emergency-area section-padding-100-50">
        <div className="container">
            <h2 className="text-white mb-50">Branches</h2>
            <div className="row">
            {
                globalState.branches.sort((a, b) => ((a.order.charCodeAt(0)) - (b.order.charCodeAt(0)))).map((branch) => (
                <div className="col-12 col-sm-6 col-lg-3" key={branch.id}>
                    <div className="single-emergency-helpline mb-50">
                        <h5>{branch.province}</h5>
                        <p>{branch.phone}</p>
                        <p>{branch.email}</p>
                        <p style={{wordWrap: "break-word", maxWidth: 200}}>{branch.address}</p>
                    </div>
                </div>
                ))
            }
            </div>
        </div>
    </div>
    </>
  )
}



export default Home;