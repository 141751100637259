import React, {useEffect, useRef} from "react";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";

const AboutUs = (props) =>
{

    const [globalState] = useStore();
    const ref = useRef();
    useEffect(() =>
    {
        if(window.looping)
            window.looping();
            
        ref?.current?.click()
    }, [])

    const sortStatics = globalState.statics.sort((a, b) => ((a.order.charCodeAt(0)) - (b.order.charCodeAt(0))));

  return (
    <>
    <section className="breadcumb-area bg-img gradient-background-overlay" style={{backgroundImage: 'url(img/bg-img/breadcumb1.jpg)'}}>
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcumb-content">
                        <h3 className="breadcumb-title">About us</h3>
                        <p>Welcome To About Us Page</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div className="medilife-features-area section-padding-100">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-lg-12">
                    <div className="features-content">
                        <h3>
                            {sortStatics.length >= 1 && (
                                sortStatics[0].title
                            )}
                        </h3>
                        <p>                  
                            {sortStatics.length >= 1 && (
                                sortStatics[0].description
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row align-items-center ">
                {sortStatics.slice(1).map((stat) => (
                <div className="col-12 col-lg-6" key={stat.id}>
                    <div className="features-content">
                        <h3>{stat.title}</h3>
                        <p>{stat.description}</p>
                    </div>
                </div>
                ))}

            </div>
        </div>
    </div>


    <div className="comments-area container">
        <h2 className="mb-50">Owners</h2>
        <ol>
            {globalState.owners.map((owner) => (
            <li className="single_comment_area" key={owner.id}>
                <div className="comment-wrapper d-flex">
                    <div className="comment-author">
                        <img src={serverPath(owner.imagePath)} alt="" />
                    </div>
                    <div className="comment-content">
                        <div className="comment-meta">
                            <a href="#" className="comment-author-name">{owner.role}</a> | 
                            <a href="#" className="comment-date">{owner.name}</a> 
                        </div>
                        <p>
                            {owner.description}
                        </p>
                    </div>
                </div>
            </li>
            ))}
        </ol>
    </div>

    <section className="medilife-tabs-area section-padding-100">
        <div className="container">
            <div className="row">

                <div className="col-12 mb-50">
                    <h2>Our Partners</h2>
                </div>
                <div className="col-12">
                    <div className="medilife-tabs-content">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {
                                globalState.partners.map((partner, index) => (
                                <li className={`nav-item`} key={partner.id} >
                                    <a className={`nav-link`} id={`${partner.id}-tab`} data-toggle="tab" href={`#${partner.id}`} role="tab" aria-controls={partner.id} aria-selected={false} {...{ref: index <= 0 ? ref : null}}>{partner.name}</a>
                                </li>
                                ))
                            }
                        </ul>

                        <div className="tab-content" id="myTabContent">
                        {
                            globalState.partners.map((partner) => (
                            <div className="tab-pane fade" id={partner.id} role="tabpanel" aria-labelledby={`${partner.id}-tab`} key={partner.id}>
                                <div className="medilife-tab-content d-md-flex align-items-center">
                                    <div className="medilife-tab-text">
                                        <h2>{partner.name}</h2>
                                        <p>{partner.description}</p>
                                    </div>
                                    <div className="medilife-tab-img">
                                        <img src={serverPath(partner.imagePath)} alt="" />
                                    </div>
                                </div>
                            </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}



export default AboutUs;