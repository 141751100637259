import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from './style.module.css';
import {
} from 'react-icons/fa';
import language from '../../localization';
import {AuthContext} from '../../authContext';
import useStore from "../../store/store";
const Navbar = (props) =>
{

  const [globalState] = useStore();

  const initMenu = [
    {
      name: "HOME", 
      mainLink: "/",
      className: "",
      order: 1
    },
    {
      name: "OUR PRODUCTS", 
      mainLink: "/products",
      className: "",
      order: 2
    },
    {
      name: "ABOUT US", 
      mainLink: "/aboutus",
      className: "",
      order: 3
    },
    {
      name: "CONTACT US", 
      mainLink: "/contactus",
      className: "donate",
      order: 4
    },
  ];

  const branches = globalState.branches.sort((a, b) => ((a.order.charCodeAt(0)) - (b.order.charCodeAt(0))));

  return (
    <header className="header-area">
      <div className="top-header-area">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 h-100">
              <div className="h-100 d-md-flex justify-content-between align-items-center">
                <p>Welcome to <span>HikmatHanifi</span> LTD</p>
                <p>Opening Hours : Saturday to Thursday - 8AM to 6PM Contact : <a href={"tel:"+branches[0]?.phone}>{branches[0]?.phone}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-header-area" id="stickyHeader">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 h-100">
              <div className="main-menu h-100">
                <nav className="navbar h-100 navbar-expand-lg">
                  <NavLink className="navbar-brand h-logo" to="/"><img src="img/core-img/logo.png" alt="Logo" /></NavLink>
                
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#medilifeMenu" aria-controls="medilifeMenu" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>

                  <div className="collapse navbar-collapse" id="medilifeMenu">
                    <ul className="navbar-nav ml-auto">
                      {
                        initMenu.map((men, indx) => (
                          men.mainLink ?
                          <li className="nav-item" key={men.name + indx}>
                            <NavLink className={({isActive}) => ["nav-link", isActive ? 'nav-item-active' : ""].join(' ')} to={men.mainLink}>{men.name} <span className="sr-only">(current)</span></NavLink>
                          </li>
                          :
                          <li className="nav-item dropdown" key={men.name + indx}>
                            <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{men.name}</NavLink>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                              {men.links.map((menLink, ndx) => (
                                <NavLink className="dropdown-item" key={ndx + menLink.name} to={menLink.link}>{menLink.name}</NavLink>
                              ))}
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                    <a href={`mailto:${branches[0]?.email}`} className="btn medilife-appoint-btn ml-30">{branches[0]?.email}</a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
  </header>
  )
}



export default Navbar