import React, {useEffect, useState} from "react";
import useStore from "../../store/store";
import SweetAlert from "../../Components/SweetAlert";
import serverPath from "../../utils/serverPath";
const AboutUs = (props) =>
{

    const [globalState] = useStore();
    useEffect(() =>
    {
        if(window.looping)
            window.looping();
    }, [])

    const branches = globalState.branches.sort((a, b) => ((a.order.charCodeAt(0)) - (b.order.charCodeAt(0))));

    
  const defaultState = {
    name: '',
    email: '',
    message: ''
  }

  const [contactForm, setContactForm] = useState({...defaultState});


  const onChange = (type, value) =>
  {
    setContactForm(prev => ({...prev, [type]: value}))
  }

  const onSubmit = async (e) => {
    e.preventDefault();

      const  emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; ;
  
      for (const key in contactForm) {
        if(key === "email" && (!emailPattern.test(contactForm[key])))
          return SweetAlert("error", "Please Enter Correct Email Address!");
        if(contactForm[key].length < 3)
          return SweetAlert("error", "All the contacts fields are requierd and at least must be 3 character long!");
      }
  
      try {
        
        
        const response = await fetch(serverPath("/contact"), {
          method: "POST",
          headers: {
            "Content-Type": "Application/json"
          },
          body: JSON.stringify(contactForm)
        });
  
        const objData = await response.json();
  
        if (objData.status === "failure") {
          return SweetAlert("error", objData.error.message);
        }
        SweetAlert("success", "Thank You! \n An Expert From Contact Us Will Contact You Shortly.");
  
        setContactForm({...defaultState});
  
      } catch (error) {
        return SweetAlert("error", error.message);
      }
      
  }

  return (
    <>
    <section className="breadcumb-area bg-img gradient-background-overlay" style={{backgroundImage: "url(img/bg-img/breadcumb3.jpg)"}}>
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcumb-content">
                        <h3 className="breadcumb-title">Contact</h3>
                        <p>Welcome To Contact Us Page</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="medilife-contact-area section-padding-100">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-8">
                    <div className="contact-form">
                        <h5 className="mb-50">Get in touch</h5>

                        <form action="#" method="post" onSubmit={onSubmit}>
                            <div className="form-group">
                                <input type="text" className="form-control text-dark" id="contact-name" placeholder="Name" onChange={(e) => onChange("name", e.target.value)} value={contactForm.name}/>
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control text-dark" id="contact-email" placeholder="Email" onChange={(e) => onChange("email", e.target.value)} value={contactForm.email}/>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control text-dark" name="message" id="message" cols="30" rows="10" placeholder="Message" onChange={(e) => onChange("message", e.target.value)} value={contactForm.message}></textarea>
                            </div>
                            <button type="submit" className="btn medilife-btn">Send Message <span>+</span></button>
                        </form>
                    </div>
                </div>

                <div className="col-12 col-lg-4">
                    <div className="content-sidebar">


                        <div className="medilife-contact-card mb-50">

                            <div className="single-contact d-flex align-items-center">
                                <div className="contact-icon mr-30">
                                    <i className="icon-doctor"></i>
                                </div>
                                <div className="contact-meta">
                                    <p>Address: {branches[0]?.address}</p>
                                </div>
                            </div>

                            <div className="single-contact d-flex align-items-center">
                                <div className="contact-icon mr-30">
                                    <i className="icon-doctor"></i>
                                </div>
                                <div className="contact-meta">
                                    <p>Phone: {branches[0]?.phone}</p>
                                </div>
                            </div>

                            <div className="single-contact d-flex align-items-center">
                                <div className="contact-icon mr-30">
                                    <i className="icon-doctor"></i>
                                </div>
                                <div className="contact-meta">
                                    <p>Email: {branches[0]?.email}</p>
                                </div>
                            </div>

                        </div>
                        

                        <div className="medilife-emergency-card bg-img bg-overlay" style={{backgroundImage: "url(img/bg-img/about1.jpg)"}}>
                            <i className="icon-smartphone"></i>
                            <h2>For Emergency calls</h2>
                            <h3>{branches[0]?.phone}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <div className="map-area mb-100">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div id="googleMap" className="googleMap">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3397.728632349345!2d65.7059395!3d31.6138948!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed671179f996b53%3A0x95dcde8bdaf14a8!2sAyubi+Roghtia+medicin+market!5e0!3m2!1sen!2s!4v1550399108145" style={{border: 0,  width: "100%", height: "100%"}} ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="medilife-emergency-area section-padding-100-50">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="emergency-content">
                        <i className="icon-smartphone"></i>
                        <h2>For Emergency calls</h2>
                        <h3>{branches[0]?.phone}</h3>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="row">
                    {
                        branches.map((branch) => (
                        <div className="col-12 col-sm-6" key={branch.id}>
                            <div className="single-emergency-helpline mb-50">
                                <h5>{branch.province}</h5>
                                <p>{branch.phone}</p>
                                <p>{branch.email}</p>
                                <p style={{wordWrap: "break-word", maxWidth: 200}}>{branch.address}</p>
                            </div>
                        </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  )
}



export default AboutUs;