import {initState} from './store';

const charityStore = () =>
{

  const setData = ({type, data}, globalState) => ({[type]: data});

  const initialState = (payload, globalState) => ({
    products: [],
    branches: [],
    categories: [],
    heros: [],
    owners: [],
    partners: [],
    statics: [],
    });
  
  return initState({
    initialState, 
    setData, 
  })
}


export default charityStore;