import React, {useEffect, useState} from "react";
import useStore from "../../store/store";
import serverPath from '../../utils/serverPath';
import dateMaker from '../../utils/dateMaker'

const Products = (props) =>
{
    const [globalState] = useStore();
    useEffect(() =>
    {
        window.looping();
    }, []);

    const [selectedProducts, setSelectedProducts] = useState([...globalState.products]);
    const [option, setOption] = useState("");

    const onChange = (e) =>
    {
        const value = e.target.value;

        if(value.length <=  0)
        {
            setSelectedProducts([...globalState.products]);
            setOption(value)
            return
        }

        const findIndex = globalState.categories.findIndex((category) => (category.name.toLowerCase() === value.toLowerCase()));
        if(findIndex >= 0)
        {
            const findCategory = globalState.categories[findIndex];
            setOption(findCategory.name);
            setSelectedProducts(globalState.products.filter((product) => (product.categoryId === findCategory.id)))
            return
        }

        setOption(value);
        
    }
    

  return (
    <>
    <section className="breadcumb-area bg-img gradient-background-overlay" style={{backgroundImage: 'url(img/bg-img/breadcumb1.jpg)'}}>
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcumb-content">
                        <h3 className="breadcumb-title">Products</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="medilife-book-an-appoinment-area" style={{position: 'relative', zIndex: 100}}>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="appointment-form-content">
                        <div className="row no-gutters align-items-start">
                            <div className="col-12 col-lg-12">
                                <div className="medilife-appointment-form">
                                    <form action="#" method="post">
                                        <div className="row align-items-end">
                                            <div>
                                                <h5 className="text-white mb-15">
                                                    Search Category
                                                </h5>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input className="form-control"id="speciality" type="text" list="category" onChange={onChange} value={option} placeholder="All*"/>
                                                    <datalist  id="category">
                                                        {globalState.categories.map((category) => (
                                                            <option key={category.id} value={category.name}>{category.name}</option>
                                                        ))}
                                                    </datalist>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section className="medilife-blog-area section-padding-100">
        <div className="container">
            <div className="row">
                <div className="col-12 ">
                    <div className="row">
                        {selectedProducts.map((product) => (
                        <div className="col-12 col-md-4" key={product.id}>
                            <div className="single-blog-area bg-gray mb-50">
                                <div className="blog-post-thumbnail">
                                    <img src={serverPath(product.imagePath)} alt="" />
                                    <div className="post-date">
                                        <a href="#">{dateMaker(product.createdAt)}</a>
                                    </div>
                                </div>
                                <div className="post-content">
                                    <a href="#" className="headline">{product.name}</a>
                                    <p>{product.formula}</p>
                                </div>
                            </div>
                        </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    </section>

    </>
  )
}



export default Products;