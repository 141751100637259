import React, { PureComponent, Suspense, lazy } from 'react';
import {Route, Routes} from 'react-router-dom';
import { AuthContext } from '../../authContext';

import Navbar from '../Navbar'
import Home from '../Home'
import AboutUs from '../AboutUs'
import Products from '../Products'
import ContactUs from '../ContactUs'
import PageNotFound from '../FourOFour'


class Layout extends PureComponent
{

  static contextType = AuthContext;
  render()
  {
    const screens = [
      {screen: '/aboutus', Component: AboutUs},
      {screen: '/products', Component: Products},
      {screen: '/contactus', Component: ContactUs},
      {screen: '/', Component: Home},
      ]
    
    return (
        <>
          <Routes>
            {
              screens.map((per, index) => {
              return (
                <Route path={`${per.screen}`} key={index} element={<per.Component />} />
              )})
            }
            <Route path="*"  element={<Home />} />
          </Routes>
        </>
    )
  }
}


export default Layout;